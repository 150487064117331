import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useNavigate } from 'react-router-dom'

import {
  ManualOccurrencePayload,
  ManualOccurrenceTag,
  ManualOccurrenceTagType,
  OccurrenceTypeName,
  translatedOccurrenceTag,
} from 'services/occurrence/types'
import { Form } from './types'
import { FormSchema } from './schemas'

import { useDebounce, useToast, useUserInfo } from 'shared/hooks'
import { Button, ComboboxItem, Modal, Textarea } from 'components'
import { SortedCustomer } from 'services/customer/types'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { AggregatedAccount } from 'services/account/types'
import { useGetAllOccurrences } from 'domains/occurrence/hooks'
import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'
import { useGetCustomers } from 'shared/hooks/customers/useGetCustomers'
import { usePostManualOccurrence } from 'services/occurrence'
import { ReactComponent as Info } from 'assets/svg/information.svg'

import styles from './CreateManualOccurrenceModal.module.scss'
import { buildPath, paths } from 'routes'

interface CreateManualOccurrenceModalProps {
  isVisible: boolean
  onClose: () => void
  customerId?: string
}

export const CreateManualOccurrenceModal = ({
  isVisible,
  onClose,
  customerId,
}: CreateManualOccurrenceModalProps) => {
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<Form>({
    mode: 'onChange',
    resolver: joiResolver(FormSchema),
  })

  const navigate = useNavigate()
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()

  const customer = watch('customer')
  const account = watch('account')
  const tag = watch('tag')
  const note = watch('note')

  const accountId = account?.id

  const [filters, setFilters] = useState({
    customerName: '',
    accountName: '',
  })

  const { mutate: createManualOccurrence } = usePostManualOccurrence()

  const {
    customers,
    isFetching: isFetchingCustomers,
    fetchNextCustomersPage,
  } = useGetCustomers(filters.customerName)

  const {
    accounts,
    isFetching: isFetchingAccounts,
    fetchNextAccountsPage,
  } = useGetAccounts(
    !!customer?.id || !!customerId,
    customer?.id || customerId,
    {
      code: filters.accountName,
    },
  )

  const { occurrences } = useGetAllOccurrences(
    0,
    {
      accountId,
      stateNames: [
        'CREATED',
        'AVAILABLE',
        'IN_PROGRESS',
        'TRAVEL_WAITING',
        'TRAVEL_SENT',
        'TRAVEL_VIEWED',
        'TRAVEL_STARTED',
        'TRAVEL_FINISHED',
        'QUEUED',
        'HIDDEN',
      ],
    },
    !!accountId,
  )

  const unfinishedOccurrence = occurrences?.data?.length

  const hasUnfinishedOccurrence = useMemo(() => {
    if (unfinishedOccurrence && accountId) {
      const tacticalOccurrence = [
        OccurrenceTypeName.RESCUE,
        OccurrenceTypeName.EMERGENCY,
        OccurrenceTypeName.ALARM,
        OccurrenceTypeName.IMMINENT,
        OccurrenceTypeName.MANUAL,
        OccurrenceTypeName.SCHEDULED,
      ]
      return occurrences.data.some((occurrence) =>
        tacticalOccurrence.includes(occurrence.typeName),
      )
    }
    return false
  }, [accountId, unfinishedOccurrence, occurrences])

  const isFormValid = useMemo(() => {
    const isCustomerValid = customerId || customer

    return isValid && isCustomerValid
  }, [isValid, customerId, customer])

  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  const onFormSubmit = (data: Form) => {
    const userId = userInfo.id

    if (userId) {
      const payload: ManualOccurrencePayload = {
        accountId: data.account.id,
        note: data.note,
        tags: [{ name: data.tag }],
        userId,
      }

      createManualOccurrence(payload, {
        onSuccess: () => {
          addToast({
            message: 'Ocorrência manual gerada com sucesso.',
            type: 'success',
          })
          reset()
          onClose()
        },
        onError: () => {
          addToast({
            message: 'Erro ao gerar a ocorrência. Tente novamente.',
            type: 'alert',
          })
        },
      })
    }
  }

  const handleViewUnfinishedOccurrence = useCallback(
    (occurrenceId: string) => {
      navigate(
        buildPath({
          path: paths.occurrence.attendance,
          params: { occurrenceId },
        }),
        {
          state: {
            readonly: true,
          },
        },
      )
    },
    [navigate],
  )

  const sortedTags = useMemo(() => {
    return Object.entries(ManualOccurrenceTag)
      .sort((a, b) => a[1].localeCompare(b[1]))
      .map((entry) => entry[1])
  }, [])

  return (
    <Modal
      isVisible={isVisible}
      onClose={() => {
        onClose()
        reset()
      }}
      title="Nova ocorrência manual"
      size="sm"
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={styles.modal}>
          {customerId ? null : (
            <Combobox
              {...register('customer')}
              onSearch={(filter) => handleFilter({ customerName: filter })}
              value={
                customer
                  ? {
                      label: 'name',
                      value: customer,
                    }
                  : undefined
              }
              label={{ text: 'Cliente' }}
              isLoading={isFetchingCustomers}
              onEndReached={fetchNextCustomersPage}
              items={parseDataToComboboxV2(customers || [], 'name')}
              onChange={(selected) => {
                const customerSelected =
                  selected as ComboboxItem<SortedCustomer>
                setValue(
                  'customer',
                  {
                    id: customerSelected.value.id,
                    name: customerSelected.value.name,
                  },
                  {
                    shouldDirty: true,
                  },
                )
                setValue('account', undefined)
                setValue('tag', undefined)
                setValue('note', '')
              }}
            />
          )}

          <Combobox
            {...register('account')}
            value={
              account
                ? {
                    label: 'aggregatedAccountName',
                    value: account,
                  }
                : undefined
            }
            disabled={!customer && !customerId}
            onSearch={(filter) => handleFilter({ accountName: filter })}
            label={{ text: 'Conta' }}
            isLoading={isFetchingAccounts}
            onEndReached={fetchNextAccountsPage}
            items={parseDataToComboboxV2(
              accounts || [],
              'aggregatedAccountName',
            )}
            onChange={(selected) => {
              const accountSelected =
                selected as ComboboxItem<AggregatedAccount>
              setValue(
                'account',
                {
                  id: accountSelected.value.id,
                  aggregatedAccountName:
                    accountSelected.value.aggregatedAccountName,
                },
                {
                  shouldDirty: true,
                },
              )
              setValue('tag', undefined)
              setValue('note', '')
            }}
          />
          {hasUnfinishedOccurrence && (
            <div className={styles.errorContainer}>
              <Info />
              <span>Há uma ocorrência em atendimento para esta conta.</span>
              <button
                onClick={() => {
                  const occurrenceId = occurrences?.data[0]?.id

                  if (occurrenceId) {
                    handleViewUnfinishedOccurrence(occurrenceId)
                  }
                }}
              >
                ACESSAR
              </button>
            </div>
          )}
          <Combobox
            {...register('tag')}
            label={{ text: 'Motivo' }}
            value={ManualOccurrenceTag[tag]}
            items={sortedTags}
            onChange={(selected) => {
              const selectedOption = selected as ManualOccurrenceTagType

              setValue('tag', translatedOccurrenceTag[selectedOption])
              setValue('note', selected, { shouldValidate: true })
            }}
            disabled={hasUnfinishedOccurrence}
          />
          <Textarea
            label="Complemento (opcional)"
            {...register('note')}
            onChange={(event) =>
              setValue('note', event.target.value, {
                shouldValidate: true,
              })
            }
            rows={10}
            value={note}
            maxLength={1024}
            className={styles.textarea}
            disabled={hasUnfinishedOccurrence || !account}
          />
        </div>

        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Cancelar"
            onClick={() => {
              onClose()
              reset()
            }}
            type="secondary"
          />
          <Button
            disabled={hasUnfinishedOccurrence || !isFormValid}
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            htmlType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}
